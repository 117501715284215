/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// Additional JS
window.daterangepicker = require('daterangepicker');
window.moment = require('moment');
require('@fortawesome/fontawesome-pro/js/all.js');
require('@fortawesome/fontawesome-pro/js/v4-shims.js');
require('jquery.fancybox/source/jquery.fancybox.js');
window.contextMenu = require('jquery-contextmenu');

// Select 2
require('select2');
$(document).ready(function() {
    $('.select2').select2({
        theme: "rmg",
        width: 'resolve',
        placeholder: $(this).attr('placeholder'),

    });
    $('.select2-multiple').select2({
        multiple : true,
        theme: "rmg",
        width: 'resolve',
        placeholder: $(this).attr('placeholder'),

    });
});

//Custom Jquery
$(document).ready(function(){

    //Hidden Sidebar Toggle - Notifications
    $('#sidebar__toggle--notifications').on('click', function(event) {
        $(this).toggleClass('active');
        $('#sidebar__hidden--notifications').toggleClass('active');
        event.preventDefault();

        //Remove active sidebar and button elements on close of other sidebar toggle
        if ($('.sidebar__hidden--settings').hasClass('active')) {
            $('.sidebar__hidden--settings').removeClass('active');
            $('.site-header__icons--middle').toggleClass('active');
        }
    });

    //Hidden Sidebar Toggle - Settings
    $('#sidebar__toggle--settings').on('click', function(event) {
        $(this).toggleClass('active');
        $('#sidebar__hidden--settings').toggleClass('active');
        event.preventDefault();

        //Remove active sidebar and button elements on close of other sidebar toggle
        if ($('.sidebar__hidden--notifications').hasClass('active')) {
            $('.sidebar__hidden--notifications').removeClass('active');
            $('.site-header__icons--last').toggleClass('active');
        }
    });

    $('.btn.btn--purple.back-button').on('click', function(){
        $('#continue-button > a').removeClass('active');
    });

    //Add Class to parent div if input checkbox is checked
    $('.sidebar__hidden--checkbox').on('click', function(event){
        $(this).parent().parent().find('.checkmark').toggleClass('block-checked');
    });

    //Tabs
    $('.tabs__nav span').on('click', function() {
        $([$(this).parent()[0], $($(this).data('href'))[0]]).addClass('active').siblings('.active').removeClass('active');
    });

    //Accordions on Legal Pages
    $('.accordions__block--title').on('click', function(event){
        $(this).toggleClass('active');
        $(this).parent().toggleClass('active');
        $(this).parent().find('.accordions__block--content').slideToggle();
    });

    // Tab groups
    $('.tabgroup > div').hide();
    $('.tabgroup > div:first-of-type').show();
    $('.tabs a').click(function(e){
        e.preventDefault();
        var $this = $(this),
            tabgroup = '#'+$this.parents('.tabs').data('tabgroup'),
            others = $this.closest('li').siblings().children('a'),
            target = $this.attr('href');
        others.removeClass('active');
        $this.addClass('active');
        $(tabgroup).children('div').hide();
        $(target).show();

    });

    // HelpHovs
    $('.helphov').hover(function(){
        $(this).parent().find('.helpInfo').fadeToggle();
    });


});

//downloads
/**
 * Show the loading animation when a
 * link is clicked or a form is submitted
 */
$(document).ready(function() {
    $('form').submit(function (e) {
        setTimeout(function () {
            $('#loader').fadeIn();
        }, 100);

        if ($(this).hasClass('download')) {
            console.log('here');
            e.preventDefault();
            var params = $(this).serializeArray();
            var xhr = new XMLHttpRequest();
            xhr.open($(this).attr('method'), $(this).attr('action'), true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function () {
                if (this.status === 200) {
                    var filename = "";
                    var disposition = xhr.getResponseHeader('Content-Disposition');
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                    }
                    var type = xhr.getResponseHeader('Content-Type');

                    var blob = new Blob([this.response], {type: type});
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        var URL = window.URL || window.webkitURL;
                        var downloadUrl = URL.createObjectURL(blob);

                        if (filename) {
                            // use HTML5 a[download] attribute to specify filename
                            var a = document.createElement("a");
                            // safari doesn't support this yet
                            if (typeof a.download === 'undefined') {
                                window.location = downloadUrl;
                            } else {
                                a.href = downloadUrl;
                                a.download = filename;
                                document.body.appendChild(a);
                                a.click();
                            }
                        } else {
                            window.location = downloadUrl;
                        }

                        setTimeout(function () {
                            URL.revokeObjectURL(downloadUrl);
                        }, 100); // cleanup
                    }
                }
            };
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.send($.param(params));

            setTimeout(function () {
                $('#loader').fadeOut();
            }, 100);
        }
    });
});
